<template>
    <right-list locale="uz" />
</template>

<script>
import RightList from '@/components/RightList'

export default {
    name: 'Uz',
    components: { RightList }
}
</script>